import React from "react"
import SelectableCard from "./selectableCard"

export class SelectableCardList extends React.Component {
  constructor(props) {
    super(props)
    var selected = props.multiple ? [] : -1
    var initialState = {
      selected: selected,
    }
    this.state = initialState
    this.onChange = this.props.onChange.bind(this)
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      if (props.multiple) {
        var selectedIndexes = prevState.selected
        var selectedIndex = selectedIndexes.indexOf(index)
        if (selectedIndex > -1) {
          selectedIndexes.splice(selectedIndex, 1)
          this.onChange(selectedIndexes)
        } else {
          if (!(selectedIndexes.length >= props.maxSelectable)) {
            selectedIndexes.push(index)
            this.onChange(selectedIndexes)
          }
        }
        return {
          selected: selectedIndexes,
        }
      } else {
        this.onChange(index)
        return {
          selected: index,
        }
      }
    })
  }

  render() {
    var { contents, multiple } = this.props
    var content = contents.map((cardContent, i) => {
      if (cardContent) {
        var {
          name,
          description,
          co2Savings,
          selected,
          nextSteps,
          icon,
          image,
          time,
          why,
          tasks,
          toConsider,
          banner,
        } = cardContent
        selected = multiple
          ? this.state.selected.indexOf(i) > -1
          : this.state.selected === i
        return (
          <div style={{ margin: "1rem" }}>
            <SelectableCard
              key={i}
              name={name}
              co2Savings={co2Savings}
              description={description}
              selected={selected}
              nextSteps={nextSteps}
              icon={icon}
              image={image}
              time={time}
              why={why}
              tasks={tasks}
              toConsider={toConsider}
              banner={banner}
              onClick={e => this.onItemSelected(i)}
            />
            <br />
          </div>
        )
      }
    })
    return (
      <div
        className="cardlist"
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "row wrap",
          // columnGap: "2%",
          // rowGap: "4em",
        }}
      >
        {content}
      </div>
    )
  }
}

export default SelectableCardList
