import React, { Component } from "react"
import ExpandableSelectableCardList from "../components/expandableSelectableCardList"

export class QuickActions extends Component {
  render() {
    return (
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <ExpandableSelectableCardList
          title="Recommended Actions"
          cardContents={this.props.content}
          multiple
          maxSelectable={2}
          selected={this.props.selected}
          onListChange={this.props.onListChange}
          getSelected={this.props.getSelected}
        />
      </div>
    )
  }
}

export default QuickActions
