import React, { Component } from "react"
import ExpandableSelectableCardList from "../components/expandableSelectableCardList"

class Projects extends Component {
  // constructor(props) {
  //   super(props)
  //   this.onListChange = this.props.onListChange.bind(this)
  // }
  render() {
    return (
      <div style={{ marginRight: "10px", marginLeft: "10px" }}>
        <ExpandableSelectableCardList
          title=""
          cardContents={this.props.content}
          multiple
          maxSelectable={this.props.content.length}
          selected={this.props.selected}
          onListChange={this.props.onListChange}
          getSelected={this.props.getSelected}
          type={this.props.type}
        />
      </div>
    )
  }
}

export default Projects
