import React, { Component } from "react"
import SelectableCardList from "./selectableCardList"

export class ExpandableSelectableCardList extends Component {
  state = {
    showMore: false,
  }
  changeShowMore = () => {
    const { showMore } = this.state
    this.setState({
      showMore: !showMore,
    })
  }

  render() {
    var arrayCards = this.props.cardContents
    const numCards = this.state.showMore ? arrayCards.length : 3
    arrayCards = arrayCards.slice(0, numCards)
    return (
      <div className="column">
        {this.props.type === "new" && (
          <div>
            {/* <h1
              className="title"
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              {this.props.title}
            </h1>
            <br/> */}
          </div>
        )}
        <SelectableCardList
          multiple={this.props.multiple}
          maxSelectable={this.props.maxSelectable}
          contents={arrayCards}
          onChange={this.props.onListChange}
        />
        <br />
        {/* Use this button to test if u can get selected */}
        {/* <button onClick={this.props.getSelected}>Get selected</button> */}
        {arrayCards.length >= 3 && (
          <button
            onClick={this.changeShowMore}
            style={{
              background: "#01b76b",
              color: "white",
              fontWeight: "bold",
              float: "right",
              borderRadius: "0.625rem",
              padding: "5px 10px",
            }}
          >
            {this.state.showMore ? (
              <span>See less projects</span>
            ) : (
              <span>See more projects</span>
            )}
          </button>
        )}
      </div>
    )
  }
}

export default ExpandableSelectableCardList
