import React from "react"
import ClickableCard from "./clickableCard"

export class SelectableCard extends React.Component {
  render() {
    var isSelectedBoolean = this.props.selected
    var isSelected = this.props.selected ? "selected" : ""
    var className = "selectable " + isSelected
    var {
      name,
      description,
      co2Savings,
      nextSteps,
      icon,
      image,
      time,
      why,
      tasks,
      toConsider,
      banner
    } = this.props
    return (
      <div>
        <ClickableCard
          name={name}
          description={description}
          co2Savings={co2Savings}
          nextSteps={nextSteps}
          icon={icon}
          image={image}
          time={time}
          why={why}
          tasks={tasks}
          toConsider={toConsider}
          className={className}
          banner={banner}
          onClick={this.props.onClick}
          selected={this.props.selected}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          }}
        >
          {isSelectedBoolean === true ? (
            <div
              style={{ margin: "-1.6rem -1.6rem 0 0", paddingBottom: "1.6em" }}
            >
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
                onClick={this.props.onClick}
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          ) : (
            <button
              className="select-button"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.1)",
                background: "white",
                float: "right",
                cursor: "pointer",
                borderRadius: "10px",
                margin: "-1em -2.5em 0 0",
                height: "35px",
                padding: "0 0.6em 0 0.6em",
              }}
              onClick={this.props.onClick}
            >
              <span
                className="svg-text"
                style={{ color: "#1db954", fontSize: "0.8rem" }}
              >
                ╋ <span style={{ color: "black" }}>Select</span>
              </span>
            </button>
          )}
        </ClickableCard>
      </div>
    )
  }
}

export default SelectableCard
