import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import firebase from "gatsby-plugin-firebase"

const NextDiv = styled.div`
  margin-left: 1rem;
  margin-right: 2rem;
  @media (min-width: 375px) {
    margin-right: 4rem;
  }
  @media (min-width: 768px) {
    margin-left: 4rem;
  }
`

export class SelectedInterests extends Component {
  state = {
    interests: [],
    tasks: [],
  }
  componentDidMount() {
    if (this.props.email) {
      firebase
        .firestore()
        .collection(process.env.GATSBY_FIRESTORE_USERS)
        .doc(this.props.email)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.setState({
              tasks: doc.data().tasks,
              interests: doc.data().interests,
            })
          }
        })
    }
  }

  updateData = interests => {
    const increment = firebase.firestore.FieldValue.increment(1)

    interests.map(interest => {
      firebase
        .firestore()
        .collection("data")
        .doc("startedProjectData")
        .update({
          totalNumberStarted: increment,
          [interest.id]: increment,
        })
        .then(console.log("Started projects updated"))
        .catch(function(error) {
          console.error("Error adding started project: ", error)
        })
    })
  }

  addProject = e => {
    e.preventDefault()
    const selectedInterests = [
      ...this.props.selectedProjectsData,
      ...this.props.selectedQuickActionData,
    ]
    firebase
      .firestore()
      .collection(process.env.GATSBY_FIRESTORE_USERS)
      .doc(this.props.email)
      .update({
        interests: [
          ...this.state.interests,
          ...selectedInterests.map(
            ({ nextSteps, image, tasks, ...keepAttrs }) => ({
              ...keepAttrs,
              tasks: tasks.map(({ description, toDo, ...keep }) => keep),
            })
          ),
        ],
        tasks: [
          ...this.state.tasks,
          ...selectedInterests.map(interest =>
            interest.tasks.map(task => {
              return { id: task.id, status: "undecided" }
            })
          ),
        ].flat(),
      })
      .then(() => {
        console.log("Document updated to Firestore with ID: ", this.props.email)
        navigate("/dashboard")
      })
      .catch(function(error) {
        console.error("Error adding document: ", error)
      })

    this.updateData(selectedInterests)
  }
  render() {
    const selectedData = [
      ...this.props.selectedProjectsData,
      ...this.props.selectedQuickActionData,
    ]
    const toRenderIcons = selectedData.map(item => (
      <div className="icon" key={item.id} />
    ))

    if (toRenderIcons <= 0) {
      return <div></div>
    } else {
      return (
        <Fade bottom>
          <div
            style={{
              position: "fixed",
              height: "75px",
              background: "#1db954",
              color: "white",
              width: "100%",
              padding: "7.5px 5%",
              overflow: "hidden",
              zIndex: "99",
              display: "flex",
              justifyContent: "flex-end",
              bottom: "0",
            }}
          >
            {toRenderIcons.length === 1 && (
              <div
                style={{
                  display: "inline",
                  paddingTop: "15px",
                  fontWeight: "700",
                }}
              >
                <b>1</b> Project Selected
              </div>
            )}
            {toRenderIcons.length !== 1 && (
              <div
                style={{
                  display: "inline",
                  paddingTop: "15px",
                  fontWeight: "700",
                }}
              >
                <b>{toRenderIcons.length}</b> Projects Selected
              </div>
            )}
            {this.props.type === "new" && (
              <Link
                to="/join"
                style={{
                  display: "inline",
                  paddingTop: "6px",
                  color: "black",
                  fontWeight: "700",
                  justifyContent: "flex-end",
                  textDecoration: "none",
                }}
                state={{
                  projects: this.props.selectedProjectsData,
                  quickActions: this.props.selectedQuickActionData,
                }}
              >
                <NextDiv
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    width: "9rem",
                    height: "2.2rem",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "9px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.85rem",
                    }}
                  >
                    Next
                  </p>
                </NextDiv>
              </Link>
            )}
            {this.props.type === "add" && (
              <button
                style={{
                  display: "inline",
                  color: "black",
                  fontWeight: "700",
                  justifyContent: "flex-end",
                  textDecoration: "none",
                }}
                onClick={this.addProject}
              >
                <NextDiv
                  style={{
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    width: "9rem",
                    height: "2.2rem",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "9px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.85rem",
                    }}
                  >
                    Add
                  </p>
                </NextDiv>
              </button>
            )}
          </div>
        </Fade>
      )
    }
  }
}

export default SelectedInterests
