/* eslint-disable */
import React, { useState, useContext, useEffect } from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import Projects from "../sections/projects"
import QuickActions from "../sections/quickActions"
import SelectedInterests from "../sections/selectedInterests"
import UserContext from "../context/UserContext"
import DefaultLayout from "../layouts/defaultLayout"

let wizardData = {}
let projectRatings = []

const PersonalizedProjects = ({ location, data }) => {
  const context = useContext(UserContext)

  // Updating wizard data with context data
  wizardData = context.wizardData
  projectRatings = context.projectRatings

  // If localStore is availible it will use that
  useEffect(() => {
    const lsWizardData = JSON.parse(localStorage.getItem("wizardData"))
    if (lsWizardData) {
      wizardData = lsWizardData
    }
    const lsProjectRatings = JSON.parse(localStorage.getItem("projectRatings"))
    if (lsProjectRatings) {
      projectRatings = lsProjectRatings
    }
  })

  // SELECTED CARDS STATE
  const [selectedProjects, setSelectedProjects] = useState([])
  const [selectedQuickActions, setSelectedQuickActions] = useState([])
  const [selectedProjectsData, setselectedProjectsData] = useState([])
  const [selectedQuickActionData, setselectedQuickActionData] = useState([])

  // change handlers for icons
  const changeProjectIcons = selectedProjects => {
    var icons = []
    selectedProjects.forEach(element => {
      if (uniqueAllProjects[element] != null) {
        icons.unshift(uniqueAllProjects[element])
      }
    })
    setselectedProjectsData(icons)
  }

  const changeQuickActionIcons = selectedQuickActions => {
    var icons = []
    selectedQuickActions.forEach(element => {
      if (
        allQuickActions[element] != null ||
        allQuickActions[element].file !== undefined
      ) {
        icons.unshift(allQuickActions[element])
      }
    })
    setselectedQuickActionData(icons)
  }

  // OnClick button handlers
  function onProjectListChange(selectedProjects) {
    setSelectedProjects(selectedProjects)
    changeProjectIcons(selectedProjects)
  }
  function onQuickActionListChange(selectedQuickActions) {
    setSelectedQuickActions(selectedQuickActions)
    changeQuickActionIcons(selectedQuickActions)
  }
  function getSelectedProjects() {
    if (selectedProjects != null) {
      window.alert("Selected Projects: " + selectedProjects)
    }
  }
  function getSelectedQuickActions() {
    if (selectedQuickActions != null) {
      window.alert("Selected Quick Actions: " + selectedQuickActions)
    }
  }

  if (data == null || location.state == null) {
    return (
      <DefaultLayout>
        <Link to="/">Go to home</Link>
      </DefaultLayout>
    )
  }

  // Getting all projects data from Contentful query
  const allProjectsAndQuickActions = data.allContentfulProjectNew.nodes

  allProjectsAndQuickActions.sort((a, b) => {
    if (projectRatings[a.id] > projectRatings[b.id]) {
      return -1
    }
    if (projectRatings[a.id] < projectRatings[b.id]) {
      return 1
    }
    return 0
  })

  // QUICK ACTIONS
  const allQuickActions = allProjectsAndQuickActions.slice(0, 2)

  // ALL PROJECTS DATA:
  // const allProjects = allProjectsAndQuickActions.slice(
  //   2,
  //   allProjectsAndQuickActions.length
  // )
  const allProjects = allProjectsAndQuickActions
  allProjects.sort((a, b) => {
    if (
      projectRatings[a.id] === undefined &&
      projectRatings[b.id] === undefined
    ) {
      return 0
    }
    if (projectRatings[a.id] === undefined) {
      if (projectRatings[b.id] > 0) {
        return 1
      } else {
        return -1
      }
    }
    if (projectRatings[b.id] === undefined) {
      if (projectRatings[a.id] > 0) {
        return -1
      } else {
        return 1
      }
    }
    if (projectRatings[a.id] > projectRatings[b.id]) {
      return -1
    }
    if (projectRatings[a.id] < projectRatings[b.id]) {
      return 1
    }
    return 0
  })

  // Removing duplicates
  const uniqueAllProjects = Array.from(new Set(allProjects.map(a => a.id))).map(
    id => {
      return allProjects.find(a => a.id === id)
    }
  )
  // Sorting array according to compare function
  uniqueAllProjects.sort(compare)

  // RETURN: this is returned if the user FILLS OUT the wizard PROPERLY
  return (
    <div>
      <SelectedInterests
        selectedProjectsData={selectedProjectsData}
        selectedQuickActionData={selectedQuickActionData}
        type="new"
      />
      <DefaultLayout>
        <SEO title="Your Personalized Projects" />
        <div>
          <p className="mb-5 text-center">
            Thank you for taking a meaningful step to change Earth for the
            better. Select the actions that suit you to put them on your
            dashboard.
          </p>
          <h1
            style={{
              fontWeight: "300",
              textAlign: "center",
              fontSize: "1.8rem",
            }}
          >
            <b>Select one or more of these actions</b>
          </h1>
        </div>
        <br />

        <div>
          <br />
          {/* <QuickActions
            selected={selectedQuickActions}
            onListChange={onQuickActionListChange}
            getSelected={getSelectedQuickActions}
            content={allQuickActions}
          />
          <br />
          <br /> */}
          <Projects
            selected={selectedProjects}
            onListChange={onProjectListChange}
            getSelected={getSelectedProjects}
            content={allProjects}
            type="new"
          />
        </div>
      </DefaultLayout>
    </div>
  )
}

function compare(a, b) {
  if (a.priority < b.priority) {
    return -1
  }
  if (a.priority > b.priority) {
    return 1
  }
  return 0
}

export const query = graphql`
  query AllProjectsNew {
    allContentfulProjectNew(sort: { fields: orderTag }) {
      nodes {
        id
        name
        slug
        description {
          json
        }
        icon {
          file {
            url
          }
        }
        takeaways {
          json
        }
        tasks {
          id
          name
          description {
            json
          }
          toDo {
            json
          }
        }
        why {
          json
        }
        toConsider {
          id
          name
          toDo {
            json
          }
        }
        banner {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default PersonalizedProjects
